import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import slide01 from './images/01-Rietberg.jpg';
import slide01x2 from './images/01-Rietberg@2x.jpg';
import slide01x3 from './images/01-Rietberg@3x.jpg';
import slide02 from './images/02-Berlin.jpg';
import slide02x2 from './images/02-Berlin@2x.jpg';
import slide02x3 from './images/02-Berlin@3x.jpg';
import slide03 from './images/03-Leipzig.jpg';
import slide03x2 from './images/03-Leipzig@2x.jpg';
import slide03x3 from './images/03-Leipzig@3x.jpg';
import slide04 from './images/04-Nürnberg.jpg';
import slide04x2 from './images/04-Nürnberg@2x.jpg';
import slide04x3 from './images/04-Nürnberg@3x.jpg';
import slide05 from './images/05-München.jpg';
import slide05x2 from './images/05-München@2x.jpg';
import slide05x3 from './images/05-München@3x.jpg';
import slide06 from './images/06-Stuttgart.jpg';
import slide06x2 from './images/06-Stuttgart@2x.jpg';
import slide06x3 from './images/06-Stuttgart@3x.jpg';
import slide07 from './images/07-Köln.jpg';
import slide07x2 from './images/07-Köln@2x.jpg';
import slide07x3 from './images/07-Köln@3x.jpg';
import slide08 from './images/08-Darmstadt.jpg';
import slide08x2 from './images/08-Darmstadt@2x.jpg';
import slide08x3 from './images/08-Darmstadt@3x.jpg';
import slide09 from './images/09-Karlsruhe.jpg';
import slide09x2 from './images/09-Karlsruhe@2x.jpg';
import slide09x3 from './images/09-Karlsruhe@3x.jpg';
import slide10 from './images/10-Liechtenstein.jpg';
import slide10x2 from './images/10-Liechtenstein@2x.jpg';
import slide10x3 from './images/10-Liechtenstein@3x.jpg';
import slide11 from './images/11-Dresden.jpg';
import slide11x2 from './images/11-Dresden@2x.jpg';
import slide11x3 from './images/11-Dresden@3x.jpg';
import slide12 from './images/12-Rostock.jpg';
import slide12x2 from './images/12-Rostock@2x.jpg';
import slide12x3 from './images/12-Rostock@3x.jpg';
import slide13 from './images/13-Hamburg.jpg';
import slide13x2 from './images/13-Hamburg@2x.jpg';
import slide13x3 from './images/13-Hamburg@3x.jpg';
import slide14 from './images/14-Bremen.jpg';
import slide14x2 from './images/14-Bremen@2x.jpg';
import slide14x3 from './images/14-Bremen@3x.jpg';
import slide15 from './images/15-Hannover.jpg';
import slide15x2 from './images/15-Hannover@2x.jpg';
import slide15x3 from './images/15-Hannover@3x.jpg';
import slide16 from './images/16-Essen.jpg';
import slide16x2 from './images/16-Essen@2x.jpg';
import slide16x3 from './images/16-Essen@3x.jpg';

const slides = [
{src:slide01, srcX2:slide01x2, srcX3:slide01x3, alt:'Danke Rietberg'},
{src:slide02, srcX2:slide02x2, srcX3:slide02x3, alt:'Danke Berlin'},
{src:slide03, srcX2:slide03x2, srcX3:slide03x3, alt:'Danke Leipzig'},
{src:slide04, srcX2:slide04x2, srcX3:slide04x3, alt:'Danke Nürnberg'},
{src:slide05, srcX2:slide05x2, srcX3:slide05x3, alt:'Danke München'},
{src:slide06, srcX2:slide06x2, srcX3:slide06x3, alt:'Danke Stuttgart'},
{src:slide07, srcX2:slide07x2, srcX3:slide07x3, alt:'Danke Köln'},
{src:slide08, srcX2:slide08x2, srcX3:slide08x3, alt:'Danke Darmstadt'},
{src:slide09, srcX2:slide09x2, srcX3:slide09x3, alt:'Danke Karlsruhe'},
{src:slide10, srcX2:slide10x2, srcX3:slide10x3, alt:'Danke Liechtenstein'},
{src:slide11, srcX2:slide11x2, srcX3:slide11x3, alt:'Danke Dresden'},
{src:slide12, srcX2:slide12x2, srcX3:slide12x3, alt:'Danke Rostock'},
{src:slide13, srcX2:slide13x2, srcX3:slide13x3, alt:'Danke Hamburg'},
{src:slide14, srcX2:slide14x2, srcX3:slide14x3, alt:'Danke Bremen'},
{src:slide15, srcX2:slide15x2, srcX3:slide15x3, alt:'Danke Hannover'},
{src:slide16, srcX2:slide16x2, srcX3:slide16x3, alt:'Danke Essen'}
]

class SlideShow extends React.Component {
	render(){
		return (
			<div className="container">
				<Carousel
					showStatus={false}
					showThumbs={false}
					infiniteLoop
					autoPlay
					useKeyboardArrows={true}
					interval={4500}
					transitionTime={500} >
					{
						slides.map((image, index) => {
							return (
								<div key={`image-${index}`} className="Carousel__image-container">
									<img
										srcSet={image.srcX3 + ' 3x,' + image.srcX2 + ' 2x,' + image.src + ' 1x'}
										src={image.src} alt={image.alt}
									/>
								</div>
							)
						})
					}
				</Carousel>
			</div>
		)
	}
}

export default SlideShow;